/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import qs from 'qs';
import { Spin, Icon } from 'antd';
import useStyles from './styles';

const userList = {
  ani: {
    avatar: 'https://ca.slack-edge.com/TG2617AP2-UK9SFAGAF-18ed62654c56-512',
    name: 'Aniruddha Ganguly',
  },
  huynh: {
    avatar:
      'https://web.whatsapp.com/pp?e=https%3A%2F%2Fpps.whatsapp.net%2Fv%2Ft61.24694-24%2F61467252_2209676462414757_626189103427223552_n.jpg%3Foe%3D5ECB8EDF%26oh%3D2ef64e8a12c31850079ccaa502521529&t=l&u=84337552807%40c.us&i=1559702461&n=qC0UTTTeJSUOS9FwcbgNNz54SIkzMwf9bBK2DhzUhkY%3D',
    name: 'Huynh Nguyen',
  },
  man: {
    avatar: 'https://f11-org-zp.zdn.vn/d33c1642e4870dd95496.jpg',
    name: 'Man Nguyen',
  },
  shashank: {
    avatar: 'https://files.slack.com/files-pri/TG2617AP2-F0127Q2L74K/dsc_0022-min.jpg',
    name: 'Shashank Modi',
  },
};

const getUserByEmail = (email) => {
  if (email.includes('ani')) {
    return userList.ani;
  }
  if (email.includes('man')) {
    return userList.man;
  }
  if (email.includes('shashank')) {
    return userList.shashank;
  }
  if (email.includes('huynh')) {
    return userList.huynh;
  }
  return {
    avatar: 'https://pickaface.net/gallery/avatar/20151205_194059_2696_Chat.png',
    name: email,
  };
};

const NegoIframe = ({
  url,
  apiUrl,
  apiKey,
  orgId,
  identity,
  name,
  email,
  brandId,
  initMessage,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const refIframe = useRef(null);
  useEffect(() => {
    if (!refIframe) {
      return;
    }
    const data = {
      organisation_id: orgId,
      brand_id: brandId,
      customer_name: name,
      customer_email: email,
      text: initMessage,
      type: 'MessageSystem',
      user: getUserByEmail(email),
    };
    refIframe.current.contentWindow.postMessage(JSON.stringify(data), '*');
  }, [orgId, name, email, brandId, initMessage, loading]);

  const params = qs.stringify({
    apiUrl,
    apiKey,
    identity,
  });
  const iframeUrl = `${url}?${params}`;

  return (
    <Spin
      wrapperClassName={classes.spinner}
      tip="Loading chat..."
      spinning={loading}
      indicator={
        <Icon
          type="loading"
          style={{
            fontSize: 24,
          }}
          spin
        />
      }
    >
      <iframe
        className={classes.iframe}
        title="nego"
        ref={refIframe}
        src={iframeUrl}
        onLoad={() => {
          setLoading(false);
        }}
      />
    </Spin>
  );
};

export default NegoIframe;
